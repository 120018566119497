import React, { useCallback, useEffect } from 'react';
import { Ticker } from 'pixi.js';
import cn from 'classnames';

import { useSpinePixi } from '@app/hooks';

import s from './MatchMakingWorm.module.scss';

interface WormConfig {
  charCode: string | undefined;
  accessoryName: string | undefined;
}

interface MatchMakingWormProps {
  playerWorm: WormConfig;
  opponentWorm?: WormConfig;
  className?: string;
}

const WORMS_SCALE = 0.65;
const WORMS_Y = 900;
const APPEAR_DURATION = 60;

export const MatchMakingWorm: React.FC<MatchMakingWormProps> = ({
  playerWorm,
  opponentWorm,
  className,
}) => {
  const { rendererRef, pixiManager, isLoaded } = useSpinePixi({});

  const animateWorm = useCallback(
    (wormName: 'player' | 'opponent') => {
      const worm = pixiManager?.getWormByLabel(`worm-${wormName}`);

      if (!worm || !rendererRef.current) {
        console.warn(`${wormName} worm not loaded`);

        return;
      }

      const ticker = Ticker.shared;

      const duration = APPEAR_DURATION;
      const startY = WORMS_Y;
      const targetY = rendererRef.current.clientHeight / 2 - 50;
      let step = 0;

      const animate = () => {
        step += 1;

        const progress = Math.min(step / duration, 1);

        worm.y = startY + (targetY - startY) * progress;

        if (progress >= 1) {
          ticker.remove(animate);
        }
      };

      ticker.add(animate);
    },
    [pixiManager, rendererRef],
  );

  useEffect(() => {
    if (isLoaded && playerWorm.charCode) {
      const worm = pixiManager?.getWormByLabel('worm-player');

      if (worm) {
        return;
      }

      pixiManager?.loadWorm(
        {
          name: 'player',
          charCode: playerWorm.charCode,
          accessoryName: playerWorm.accessoryName,
          offset: { x: -70, y: WORMS_Y },
          initialScale: WORMS_SCALE,
          mirror: true,
          initialAnimation: { name: 'matchmaking-idle', loop: true },
        },
        () => animateWorm('player'),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, playerWorm]);

  useEffect(() => {
    if (opponentWorm?.charCode) {
      const worm = pixiManager?.getWormByLabel('worm-opponent');

      if (worm) {
        return;
      }

      pixiManager?.loadWorm(
        {
          name: 'opponent',
          charCode: opponentWorm.charCode,
          accessoryName: opponentWorm.accessoryName,
          offset: { x: 0, y: WORMS_Y },
          initialScale: WORMS_SCALE,
          initialAnimation: { name: 'matchmaking-emotion2', loop: true },
        },

        () => {
          animateWorm('opponent');

          pixiManager?.changeWormAnimation(
            'worm-player',
            'matchmaking-emotion1',
            {
              force: true,
              loop: true,
            },
          );
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opponentWorm]);

  return <div className={cn(s.root, className)} ref={rendererRef} />;
};
