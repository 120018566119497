import React from 'react';
import { clsx } from 'clsx';
import cn from 'classnames';

import { useSpinePixi } from '@app/hooks';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';

import s from './PlayWormAvatar.module.scss';

interface PlayWormAvatarProps {
  charCode?: string;
  accessoryName?: string;
  className?: string;
}

export const PlayWormAvatar: React.FC<PlayWormAvatarProps> = ({
  charCode,
  accessoryName,
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const userCharCode = userProfile?.versus?.character.code;

  const currentCode = charCode ?? userCharCode;
  const currentAccessory =
    accessoryName ?? userProfile?.versus?.character.accessories?.[0]?.code;

  const wormAssets = versusCharacterService.generateCharAssets(currentCode);

  const { rendererRef, isLoaded } = useSpinePixi({
    worms: [
      {
        name: 'player',
        charCode: currentCode,
        accessoryName: currentAccessory,
        initialScale: 0.75,
        offset: { x: -70 },
      },
    ],
  });

  return (
    <>
      <div className={clsx(s.root, className)}>
        <img
          src={wormAssets?.logoLg}
          alt="worm"
          className={cn(s.avatarNameImage, { [s.loaded]: isLoaded })}
        />
        <div
          className={cn(s.worm, { [s.loaded]: isLoaded })}
          ref={rendererRef}
        />
      </div>
    </>
  );
};
