import { SpinePixiManager, SpineWormConfig } from '@app/utils';
import { useEffect, useRef, useState } from 'react';

interface UsePixiProps {
  worms?: SpineWormConfig[];
}

export const useSpinePixi = ({ worms }: UsePixiProps) => {
  const rendererRef = useRef<HTMLDivElement | null>(null);
  const pixiManagerRef = useRef<SpinePixiManager | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (rendererRef.current) {
      pixiManagerRef.current = new SpinePixiManager(rendererRef.current);
      pixiManagerRef.current.init().then(() => setIsLoaded(true));
    }

    return () => {
      pixiManagerRef.current?.destroy();
      pixiManagerRef.current = null;
    };
  }, []);

  useEffect(() => {
    const loadWorms = async () => {
      if (pixiManagerRef.current && worms?.length) {
        setIsLoaded(false);

        for (const worm of worms) {
          await pixiManagerRef.current.loadWorm(worm);
        }

        setIsLoaded(true);
      }
    };

    loadWorms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(worms)]);

  return {
    rendererRef,
    isLoaded,
    pixiManager: pixiManagerRef.current,
  };
};
