import { Application, Assets, Container, Renderer } from 'pixi.js';
import {
  Spine,
  TextureAtlas,
  SpineTexture,
} from '@esotericsoftware/spine-pixi-v8';
import transparentPng from '@media/transparent-1px.png';
import { versusCharacterService } from '@app/services';
import { VersusAccessoryLocation } from '@app/types';

const OBJECT_WORM = 'worm-';

export interface SpineWormConfig {
  name: string;
  charCode: string | undefined;
  accessoryName?: string | null;
  initialScale?: number;
  mirror?: boolean;
  offset?: { x?: number; y?: number };
  initialAnimation?: { name: string; loop: boolean };
  onWormAnimationComplete?: (app: SpinePixiManager) => void;
}

interface AnimationOptions {
  loop?: boolean;
  force?: boolean;
  speed?: number;
  onComplete?: () => void;
}

export class SpinePixiManager {
  app: Application<Renderer> | null = null;
  container: HTMLDivElement | null = null;
  root: Container = new Container();

  constructor(container: HTMLDivElement) {
    this.container = container;
  }

  async init() {
    if (!this.container) {
      return;
    }

    this.app = new Application();
    await this.app.init({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      resizeTo: this.container,
      backgroundAlpha: 0,
    });

    this.container.appendChild(this.app.canvas);

    this.app.stage.addChild(this.root);

    window.addEventListener('resize', this.handleResizeScene);

    this.handleResizeScene();
  }

  private async getAccessorySlotData(accessoryName?: string | null) {
    const accessorySlotMap: Record<string, string | null> = {
      [VersusAccessoryLocation.Face]: 'slap_2_worm_2.png',
      [VersusAccessoryLocation.Head]: 'slap_2_worm_5.png',
      [VersusAccessoryLocation.Body]: 'slap_2_worm.png',
    };

    const accessoryData = accessoryName
      ? await versusCharacterService.getSkinOrAccessoryFromIndexedDb(
          accessoryName,
          true,
        )
      : null;

    const accessorySlot =
      accessorySlotMap[accessoryData?.itemLocation || ''] || null;

    const getAccessoryBlob = (slotName: string) => {
      return accessorySlot === slotName
        ? accessoryData?.blob || transparentPng
        : transparentPng;
    };

    return { getAccessoryBlob };
  }

  async loadWorm(config: SpineWormConfig, onLoad?: () => void) {
    if (!this.app || !config.charCode) {
      return;
    }

    const label = `${OBJECT_WORM}${config.name}`;

    this.removeCurrentWorm(label);

    const { getAccessoryBlob } = await this.getAccessorySlotData(
      config.accessoryName,
    );

    const [skeletonData, atlasData, commonHands1, commonHands2, wormSkin] =
      await Promise.all([
        versusCharacterService.getItemFromIndexedDB('skeleton'),
        versusCharacterService.getItemFromIndexedDB('atlas'),
        versusCharacterService.getItemFromIndexedDB('commonHands1'),
        versusCharacterService.getItemFromIndexedDB('commonHands2'),
        versusCharacterService.getSkinOrAccessoryFromIndexedDb(
          config.charCode,
          false,
        ),
      ]);

    if (
      !skeletonData ||
      !atlasData ||
      !commonHands1 ||
      !commonHands2 ||
      !wormSkin
    ) {
      console.error('Missing required assets');

      return;
    }

    const assetKeys = {
      skeleton: `${label}-skeleton-${config.accessoryName}`,
      atlas: `${label}-atlas-${config.accessoryName}`,
      hands1: `${label}-hands1`,
      hands2: `${label}-hands2`,
      head: `${label}-head-${config.accessoryName}`,
      face: `${label}-face-${config.accessoryName}`,
      body: `${label}-body-${config.accessoryName}`,
      wormSkin: `${label}-wormSkin-${config.accessoryName}`,
    };

    const base64 = atlasData.blob.replace(/^data:.*;base64,/, '');
    const atlasText = atob(base64);

    const updatedAtlasText = atlasText
      .replace(/slap_2_worm.png/g, assetKeys.body)
      .replace(/slap_2_worm_2.png/g, assetKeys.face)
      .replace(/slap_2_worm_5.png/g, assetKeys.head)
      .replace(/slap_2_worm_3.png/g, assetKeys.hands1)
      .replace(/slap_2_worm_4.png/g, assetKeys.hands2)
      .replace(/slap_2_worm_6.png/g, assetKeys.wormSkin);

    const updatedAtlas = btoa(updatedAtlasText);

    const spineAssets = {
      [assetKeys.skeleton]: skeletonData.blob,
      [assetKeys.atlas]: `data:text/plain;base64,${updatedAtlas}`,
      [assetKeys.head]: getAccessoryBlob('slap_2_worm_5.png'),
      [assetKeys.face]: getAccessoryBlob('slap_2_worm_2.png'),
      [assetKeys.body]: getAccessoryBlob('slap_2_worm.png'),
      [assetKeys.hands1]: commonHands1.blob,
      [assetKeys.hands2]: commonHands2.blob,
      [assetKeys.wormSkin]: wormSkin.blob,
    };

    for (const [alias, base64] of Object.entries(spineAssets)) {
      if (alias.includes('-atlas-')) {
        Assets.add({ alias, src: base64, loadParser: 'loadTxt' });
      } else if (alias.includes('-skeleton-')) {
        Assets.add({ alias, src: base64, loadParser: 'loadJson' });
      } else {
        Assets.add({ alias, src: base64 });
      }
    }

    const { [assetKeys.atlas]: wormAtlas } = await Assets.load(
      Object.values(assetKeys),
    );

    const atlas = new TextureAtlas(wormAtlas);

    Assets.cache.set('wormAtlas', atlas);

    for (const page of atlas.pages) {
      const sprite = Assets.cache.get(page.name);

      page.setTexture(SpineTexture.from(sprite.source));
    }

    const worm = Spine.from({
      atlas: 'wormAtlas',
      skeleton: assetKeys.skeleton,
    });

    const mirrorMultiplier = config?.mirror ? -1 : 1;
    const scale = config?.initialScale ?? 0.85;

    worm.scale.set(scale * mirrorMultiplier, scale);

    worm.state.data.defaultMix = 0.2;
    worm.state.setAnimation(
      0,
      config?.initialAnimation?.name || 'idle',
      config?.initialAnimation?.loop ?? true,
    );
    worm.skeleton.setSkinByName('default');
    worm.label = label;
    worm.y =
      this.root.height / 2 +
      worm.getBounds().height / 2 +
      (config?.offset?.y || 0);
    worm.x = this.root.width / 2 + (config?.offset?.x ?? 0);

    worm.state.addListener({
      complete: () => config.onWormAnimationComplete?.(this),
    });

    this.root.addChild(worm);

    onLoad?.();
  }

  handleResizeScene = () => {
    if (this.app && this.container) {
      this.app.renderer.resize(
        this.container.clientWidth,
        this.container.clientHeight,
      );

      this.resizeWorms();
    }
  };

  resizeWorms() {
    if (!this.container) {
      return;
    }

    const scale = Math.max(
      Math.min(
        this.container.clientWidth / 600,
        this.container.clientHeight / 1100,
      ),
      0.4,
    );

    this.root.scale.set(scale, scale);
    this.root.x = this.container.clientWidth / 2;
    this.root.y = this.container.clientHeight / 2;
  }

  removeCurrentWorm(label: string) {
    const worm = this.getWormByLabel(label);

    if (worm) {
      this.root.removeChild(worm);
      worm.destroy(true);
    }
  }

  getWorms() {
    const regex = new RegExp(`^${OBJECT_WORM}`, 'i');
    const worms = this.app?.stage.getChildrenByLabel(regex);

    if (worms) {
      return worms as Spine[];
    }

    return null;
  }

  getWormByLabel(label: string) {
    const wormLabel = `${OBJECT_WORM}${label.replace(OBJECT_WORM, '')}`;
    const worm = this.root.getChildByLabel(wormLabel);

    if (worm) {
      return worm as Spine;
    }

    return null;
  }

  changeWormAnimation(
    wormName: string,
    animationName: string,
    options: AnimationOptions = {},
  ) {
    const { loop = false, force = false, onComplete, speed = 1 } = options;

    const worm = this.getWormByLabel(wormName);
    const animationState = worm?.state;
    const skeletonData = worm?.skeleton?.data;

    if (!animationState || !skeletonData) {
      console.error(
        'No animation state or skeleton data available.',
        animationName,
      );

      return;
    }

    const hasAnimation = skeletonData.findAnimation(animationName);

    if (!hasAnimation) {
      console.error(`Animation "${animationName}" not found.`);

      return;
    }

    if (force) {
      animationState.setAnimation(0, animationName, loop);
      animationState.timeScale = speed;
    } else {
      animationState.addAnimation(0, animationName, loop);
    }

    animationState.addListener({
      start: (entry) => {
        if (entry.animation?.name === animationName) {
          animationState.timeScale = speed;
        }
      },
      complete: (entry) => {
        if (entry.animation?.name === animationName) {
          onComplete?.();
        }
      },
    });
  }

  destroy() {
    window.removeEventListener('resize', this.handleResizeScene);
    this.root.children.forEach((el) => {
      el.removeAllListeners();

      if (el instanceof Spine) {
        el.state.clearListeners();
      }
    });

    Assets.reset();

    this.app?.stage.removeAllListeners();
    this.root.destroy();
    this.app?.destroy(true, true);
    this.app = null;
    this.container = null;
  }
}
