import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { BottomButton, ShopForCard } from '@components';
import { useSpinePixi } from '@app/hooks';
import { WormdrobeItem } from './WormdrobeItem';
import { useServiceState } from '@app/common/state';
import {
  apiService,
  userService,
  versusCharacterService,
  versusShopService,
  versusTournamentService,
} from '@app/services';

import s from './Wormdrobe.module.scss';

interface WormdrobeProps {
  mode: 'default' | 'tournament' | undefined;
  className?: string;
}

const CHAR_HIDE_SHOW_TIME = 300;

export const Wormdrobe: React.FC<WormdrobeProps> = ({
  mode = 'default',
  className,
}) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { tournamentData } = useServiceState(versusTournamentService, [
    'tournamentData',
  ]);
  const { charCode, accessoryCode } = versusCharacterService.getCharDataByCode(
    mode,
    userProfile?.versus?.character,
    tournamentData?.character,
  );

  const [selectedItem, setSelectedItem] = useState<string | null>(
    accessoryCode || null,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [delayedIsLoaded, setDelayedIsLoaded] = useState(false);

  const { userAccessories } = useServiceState(versusShopService, [
    'userAccessories',
  ]);

  const { rendererRef, isLoaded } = useSpinePixi({
    worms: [
      {
        name: 'equip',
        charCode: charCode,
        accessoryName: selectedItem,
        initialScale: 0.8,
        offset: { x: -40, y: 130 },
      },
    ],
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoaded) {
      timeout = setTimeout(() => {
        setDelayedIsLoaded(true);
      }, CHAR_HIDE_SHOW_TIME);
    }

    return () => clearTimeout(timeout);
  }, [isLoaded]);

  const selectAccessory = (skinName: string | null) => {
    if (skinName === selectedItem) {
      return;
    }

    setDelayedIsLoaded(false);
    setTimeout(() => {
      setSelectedItem(skinName);
    }, CHAR_HIDE_SHOW_TIME);
  };

  const confirmSelect = async () => {
    setIsLoading(true);

    try {
      if (mode === 'default') {
        await versusCharacterService.applyAccessory(selectedItem);

        navigate('/play');
      }

      if (mode === 'tournament') {
        await versusTournamentService.setAccessory(selectedItem);
        navigate('/tournament-char-manage');
      }
    } catch (e) {
      apiService.notifyError(e);
    }

    setIsLoading(false);
  };

  return (
    <div className={cn(s.root, className)}>
      <div
        className={cn(s.wormWrapper, { [s.visible]: delayedIsLoaded })}
        style={{ transitionDuration: `${CHAR_HIDE_SHOW_TIME}ms` }}
      >
        <div className={s.worm} ref={rendererRef} />
      </div>

      <div className={s.wrapper}>
        <div className={s.scrollContainer}>
          <div className={s.itemsContainer}>
            <WormdrobeItem
              data={null}
              isDeselect
              onClick={selectAccessory}
              isActive={false}
              disabled={!delayedIsLoaded}
            />
            {userAccessories?.map((el) => (
              <WormdrobeItem
                key={el.code}
                data={el}
                isActive={selectedItem === el.code}
                onClick={selectAccessory}
                disabled={!delayedIsLoaded}
              />
            ))}
          </div>
          <ShopForCard variant="wormdrobe" />
        </div>
      </div>
      <BottomButton
        label={!isLoading ? 'Select' : 'Loading..'}
        disabled={isLoading}
        onClick={confirmSelect}
        className={s.bottomButton}
      />
    </div>
  );
};
