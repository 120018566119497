import React, { useEffect, useRef, useState, RefObject } from 'react';
import { clsx } from 'clsx';
import { SpinePixiManager, vibrate } from '@app/utils';
import {
  onboardingService,
  versusAudioService,
  versusService,
} from '@app/services';

import { useServiceState } from '@app/common/state';
import { EyeBlinkAnimation } from '../EyeBlinkAnimation';
import { HitForce } from '@app/types';
import { useSpinePixi } from '@app/hooks';

import s from './WormBattle.module.scss';

interface WormBattleProps {
  className?: string;
  zIndex?: number;
}

const WORM_NAME = 'battle';

export const WormBattle: React.FC<WormBattleProps> = ({
  zIndex,
  className,
}) => {
  const {
    opponent,
    roundState,
    isDetectForce,
    fightState,
    playerHitForce,
    opponentHitForce,
  } = useServiceState(versusService, [
    'opponent',
    'roundState',
    'isDetectForce',
    'fightState',
    'playerHitForce',
    'opponentHitForce',
  ]);
  const currentIdleIteration = useRef(0);
  const accessoryName = opponent?.character.accessories?.[0]?.code;

  const { rendererRef, pixiManager } = useSpinePixi({
    worms: [
      {
        name: WORM_NAME,
        charCode: opponent?.character.code,
        accessoryName,
        initialScale: 0.9,
        initialAnimation: { name: 'idle', loop: false },
        offset: { x: -70, y: 90 },
        onWormAnimationComplete: (app) => handleIdleAnimationSequence(app),
      },
    ],
  });

  const [showEye, setShowEye] = useState(false);
  const idleSequenceRef = useRef(0);
  const roundStateRef = useRef('');

  const { isOnboarding } = useServiceState(onboardingService, ['isOnboarding']);

  useEffect(() => {
    roundStateRef.current = roundState;
  }, [roundState]);

  useEffect(() => {
    const slapAnimationOptions = {
      force: true,
      speed: 1.5,
    };

    if (roundState !== 'fight' && idleSequenceRef.current === 0) {
      pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });
    } else if (fightState === 'playerTurn') {
      if (playerHitForce === HitForce.splendid) {
        pixiManager?.changeWormAnimation(WORM_NAME, 'slap-max', {
          force: true,
          speed: 1.6,
        });
        versusAudioService.playSoundEffect('maxSlapOutgoing');

        if (accessoryName) {
          pixiManager?.changeWormAnimation(
            WORM_NAME,
            Math.random() < 0.5
              ? 'accessoriesComeback'
              : 'accessoriesComeback2',
            { speed: 2 },
          );
        }

        pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });
      } else if (playerHitForce === HitForce.okay) {
        versusAudioService.playSoundEffect('midSlapOutgoing');

        pixiManager?.changeWormAnimation(
          WORM_NAME,
          'slap-mid',
          slapAnimationOptions,
        );
        pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });
      } else if (playerHitForce === HitForce.miss) {
        versusAudioService.playSoundEffect('missSlapOutgoing');

        pixiManager?.changeWormAnimation(
          WORM_NAME,
          'slap-miss',
          slapAnimationOptions,
        );
        pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });
      }
    } else if (fightState === 'opponentTurn') {
      // player?.animationState?.clearTracks();

      if (opponentHitForce === HitForce.miss) {
        pixiManager?.changeWormAnimation(
          WORM_NAME,
          'incoming-slap-miss',
          slapAnimationOptions,
        );
        versusAudioService.playSoundEffect('missSlapIncoming');

        pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });
      } else {
        pixiManager?.changeWormAnimation(
          WORM_NAME,
          'incoming-slap',
          slapAnimationOptions,
        );
        pixiManager?.changeWormAnimation(WORM_NAME, 'idle', { loop: true });

        if (opponentHitForce === HitForce.okay) {
          versusAudioService.playSoundEffect('midSlapIncoming');
        }

        if (opponentHitForce === HitForce.splendid) {
          versusAudioService.playSoundEffect('maxSlapIncoming');

          setTimeout(() => setShowEye(true), 520);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundState, fightState, playerHitForce, opponentHitForce, accessoryName]);

  const handleIdleAnimationSequence = (app: SpinePixiManager) => {
    const maxIdleCount = 3;

    if (!app) {
      console.error('pixiManager is null. Aborting animation.');

      return;
    }

    if (roundStateRef.current !== 'fight') {
      if (currentIdleIteration.current < maxIdleCount) {
        app.changeWormAnimation(WORM_NAME, 'idle', {
          loop: false,
        });
        currentIdleIteration.current++;
      } else {
        app.changeWormAnimation(WORM_NAME, 'idle2', {
          loop: false,
        });
        currentIdleIteration.current = 0;
      }
    }
  };

  const handleTap = (event: React.TouchEvent<HTMLDivElement>) => {
    vibrate();

    if (roundState !== 'detectForce' || isDetectForce) {
      return;
    }

    if (isOnboarding) {
      onboardingService.registerTap();
    } else {
      versusService.emitHit();
    }
  };

  return (
    <>
      <div className={clsx(s.root, className)} style={{ zIndex }}>
        <div className={s.tapArea} onTouchStart={handleTap}></div>
        <div
          className={s.worm}
          ref={rendererRef as RefObject<HTMLDivElement>}
        />
      </div>
      {showEye && (
        <EyeBlinkAnimation onAnimationEnd={() => setShowEye(false)} />
      )}
    </>
  );
};

const randomMaxCount = () => 2 + Math.floor(Math.random() * 3);
